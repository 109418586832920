export enum ApplicationLocations {
  LOADING = '/loading',
  LOGIN = '/login',
  REGISTER = '/register',
  VERIFY = '/verify',
  PICK_USERNAME = '/pick-username',
  RESET_PASSWORD = '/reset-password',
  PROFILE = '/profile',
  USER_PROFILE = '/profile/user',
  BUDDY_REQUEST = '/profile/request',
  EDIT_PROFILE = '/edit-profile',
  //todo
  EDIT_ACTIVITY = '/edit-activity',
  SETTINGS = '/settings',
  BUDDIES = '/buddies',
  NOTIFICATIONS = '/notifications',
  EXPLORE = '/explore',
  ACTIVITIES = '/activities',
  CHOOSE_LOCATION = '/choose-location',
  ACTIVITY_DETAIL = '/activity/detail',
  ACTIVITY_MEMBERS = '/activity-members',
  ACTIVITY_INVITE_MEMBERS = '/activity-invite',
  ACTIVITY_INVITE = '/activities/request',
  CREATE = '/create',
  SEARCH = '/search',
  MAP = '/map',
  CHAT = '/chat',
  AUTHENTICATION_METHOD = '/',
  FORGOTTEN_PASSWORD = '/forgotten-password',
  ACCOUNT_SETTINGS = '/account-settings',
  CHANGE_PASSWORD = '/change-password'
}
